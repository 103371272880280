import React from "react"
import Vimeo from "@u-wave/react-vimeo"

import Layout from "../components/layout"
import Banner from "../components/banner"

const VideoPage = () => (
    <Layout>
      <section className="video-page">
        <Banner
          mediaType="Video"
          mediaDuration="Oct 14, 2020  |  52 mins "
          mediaText="Recap: Urgent Means Project Virtual Town Hall"
        />
        <div className="video-preview">
          <Vimeo
            id="video-townhall-20201014"
            video={472852757}
            autoplay={false}
            showTitle={false}
            showByline={false}
            showPortrait={false}
            controls={true}
          />
          <p className="body-text">The October 14, 2020 Urgent Means Project Virtual Town Hall featured a discussion about constricted access to social services in Chicago with Congressman Danny K. Davis representing the 7th District of Illinois; RADM (ret) James M. Galloway, MD, FACP, FACC, Community Health Research Advisor, former Assistant U.S. Surgeon General; Karriem Watson, DHSc, MS, MPH, Associate Executive Director, UI Health Mile Square Health Center; Stephen B. Brown, MSW LCSW, Director of Preventive Emergency Medicine, University of Illinois Hospital and Health Sciences System; and Brielle Osting, Senior Community Engagement Manager at CareAdvisors.</p>
          <p className="body-text">The discussion was moderated by Terry Mason, MD, WVON 1690 AM Radio host.</p>
        </div>
      </section>
    </Layout>
)

export default VideoPage
